<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update'
          ? $lang.business_traveller.crud.edit
          : $lang.business_traveller.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
          err_msg
        }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody></CCardBody>
        <CCardFooter>
          <CButton type="submit" size="sm" color="primary">
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
          &nbsp;
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.business_traveller.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <!--<CButton color="primary" v-on:click="createTableRow" v-if='checkPermission("create-business-traveller")' style="margin-right: 10px;">{{ $lang.buttons.general.crud.create }} </CButton>-->
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="
                      exports(self, 'pdf', module, 'Business Travellers')
                    "
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.fromDate
                    }}</label>
                  <input
                      type="date"
                      :placeholder="$lang.business_traveller.filter.fromDate"
                      class="form-control"
                      v-model="business_traveller.fromDate"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.toDate
                    }}</label>
                  <input
                      type="date"
                      :placeholder="$lang.business_traveller.filter.toDate"
                      class="form-control"
                      v-model="business_traveller.toDate"
                  />
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.industry
                    }}</label>
                  <v-select
                      multiple
                      :options="industryOptions"
                      v-model="business_traveller.industry"
                      @option:selected="getSubIndustry"
                  >
                  </v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.subIndustry
                    }}</label>
                  <v-select
                      multiple
                      :options="subIndustryOptions"
                      v-model="business_traveller.subIndustry"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.area
                    }}</label>
                  <input
                      type="text"
                      v-model="business_traveller.area"
                      class="form-control"
                  />
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.country
                    }}</label>
                  <v-select
                      :options="countryOptions"
                      v-model="business_traveller.country"
                      @option:selected="loadState"
                  >
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.state
                    }}</label>
                  <v-select
                      :options="StateOptions"
                      v-model="business_traveller.state"
                      @option:selected="loadCity"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.city
                    }}</label>
                  <v-select
                      :options="cityOptions"
                      v-model="business_traveller.city"
                  >
                  </v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.travellingCountry
                    }}</label>
                  <v-select
                      :options="travellingCountryOptions"
                      v-model="business_traveller.travellingCountry"
                      @option:selected="loadTravellingState"
                  >
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.travellingState
                    }}</label>
                  <v-select
                      :options="travellingStateOptions"
                      v-model="business_traveller.travellingState"
                      @option:selected="loadTravellingCity"
                  >
                  </v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.travellingCity
                    }}</label>
                  <v-select
                      :options="travellingCityOptions"
                      v-model="business_traveller.travellingCity"
                  >
                  </v-select>
                </div>
              </CCol>

              <CCol sm="3" v-show="!iAmUser">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.profileVerified
                    }}</label>
                  <v-select
                      :options="profileVerifiedOptions"
                      v-model="business_traveller.profileVerified"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3" v-show="!iAmUser">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.business_traveller.filter.user
                    }}</label>
                  <v-select
                      :options="userOptions"
                      v-model="business_traveller.user"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              :url="travellerListUrl"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Business Travellers')"
          >
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #area="data">
              <span :title="data.row.area">{{
                  trimfunction(data.row.area, 20)
                }}</span>
            </template>
            <template #duration="data">
              <span :title="data.row.duration">{{
                  trimfunction(data.row.duration, 20)
                }}</span>
            </template>
            <template #profilerAddress="data">
              <span :title="data.row.profilerAddress">{{
                  trimfunction(data.row.profilerAddress, 20)
                }}</span>
            </template>
            <template #visiting="data">
              <span :title="data.row.visiting">{{
                  trimfunction(data.row.visiting, 20)
                }}</span>
            </template>
            <template #userWithCompany="data">
              <Avatar
                  :image="data.row.avatar"
                  :defaultImage="data.row.avatar"
                  :content="data.row.userWithCompany"
                  :length="20"
              />
            </template>

            <template #actions="data">
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  v-on:click="viewTableRow(data.row.id, data.row.userId)"
                  v-if="checkPermission('list-business-traveller')"
              >
                <i class="fas fa-eye"></i>
              </CButton>

              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-business-traveller')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  businessTraveller,
  industry,
  users,
  profileSubIndustry,
  country,
  state,
  city,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import BeforeFilter from "../BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "BusinessTraveller",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    Avatar,
  },
  data() {
    return {
      iAmUser: "",
      currentId: "",
      userId: "",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false /* for filter toggle*/,
      err_msg: "",
      travellerListUrl: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      travellingCountryOptions: [],
      travellingStateOptions: [],
      travellingCityOptions: [],
      statusOptions: [],
      userOptions: [],
      getSubIndustries: [],
      getIndustries: [],
      getDBIndustry: [],
      profileVerifiedOptions: [],
      getDBSubIndustry: [],
      activePage: 1,
      module: businessTraveller,
      moduleIndustry: industry,
      moduleSubIndustry: profileSubIndustry,
      moduleUser: users,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,

      business_traveller: {
        area: "",
        from_date: "",
        to_date: "",
        city: "",
        state: "",
        country: "",
        travellingState: "",
        travellingCity: "",
        travellingCountry: "",
        is_active: "",
      },
      columns: [
        "userWithCompany",
        "area",
        "duration",
        "profilerAddress",
        "visiting",
        "status",
        "updatedAt",
        "actions"
      ],
      data: [],
      options: {
        headings: {
          userWithCompany: this.$lang.common.form.userName,
          area: this.$lang.business_traveller.table.area,
          duration: this.$lang.business_traveller.table.duration,
          profilerAddress: this.$lang.business_traveller.table.from,
          visiting: this.$lang.business_traveller.table.visiting,
          status: this.$lang.business_traveller.table.status,
          updatedAt: this.$lang.common.table.updatedAt,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [
          "user",
          "area",
          "duration",
          "userAddress",
          "profilerAddress",
          "visiting",
        ],
        sortable: ["user", "area", "duration", "userAddress", "profilerAddress", "visiting"],
        filterable: ["usern", "area", "duration", "userAddress", "profilerAddress", "visiting"],
      },
    };
  },
  created() {
    let self = this;
    self.travellerListUrl = "/business-traveller/list/";
    self.userId = this.$route.params.user_id;
    if (typeof self.userId !== "undefined" || self.userId != null) {
      self.travellerListUrl = this.travellerListUrl + self.userId;
    } else {
      self.travellerListUrl = this.travellerListUrl;
    }
  },
  mounted() {
    let self = this;
    // this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;

    self.iAmUser = localStorage.getItem("iAmUser");

    //Filter
    self.profileVerifiedOptions.push(
        {value: "1", label: "Pending"},
        {value: "2", label: "Verified"},
        {value: "3", label: "Blocked"}
    );

    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.username});
      });
    });

    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        if (value.industryId === "") {
          self.industryOptions.push({value: value.id, label: value.industry});
        }
      });
    });
    axios.get(this.listUrlApi(this.moduleCountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
        self.travellingCountryOptions.push({
          value: value.id,
          label: value.name,
        });
      });
    });

    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    viewTableRow(item, userId) {
      this.$router.push({path: `business-traveller/view/${item}/${userId}`});
    },
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    loadState() {
      let self = this;
      const postData = {countryId: self.business_traveller.country.value};
      self.business_traveller.state = {};
      self.business_traveller.city = {};
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.business_traveller.state.value};
      self.business_traveller.city = {};
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },

    loadTravellingState() {
      let self = this;
      const postData = {
        countryId: self.business_traveller.travellingCountry.value,
      };
      self.business_traveller.travellingState = {};
      self.business_traveller.travellingCity = {};
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.travellingStateOptions = [];
            response.data.data.map(function (value, key) {
              self.travellingStateOptions.push({
                value: value.id,
                label: value.name,
              });
            });
          });
    },
    loadTravellingCity() {
      let self = this;
      const postData = {
        stateId: self.business_traveller.travellingState.value,
      };
      self.business_traveller.travellingCity = {};
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.travellingCityOptions = [];
            response.data.data.map(function (value, key) {
              self.travellingCityOptions.push({
                value: value.id,
                label: value.name,
              });
            });
          });
    },

    getSubIndustry() {
      let self = this;
      let getIndustries = [];
      this.business_traveller.industry.map(function (value, key) {
        getIndustries[key] = value.value;
      });
      const postData = {parentId: getIndustries, array: true};
      axios
          .post(this.listUrlApi(this.moduleIndustry), postData)
          .then((response) => {
            self.subIndustryOptions = [];
            response.data.data.map(function (value, key) {
              if (value.industryId > 0) {
                self.subIndustryOptions.push({
                  value: value.id,
                  label: value.SubIndustry,
                });
              }
            });
          });
    },

    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.worktype.isActive = 1;
      } else {
        self.worktype.isActive = 0;
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                }
              });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: "BusinessTraveller Create"});
    },
    editTableRow(item) {
      let self = this;
      self.submitType = "Update";
      self.editId = item;
      this.$router.push({path: `business-traveller/edit/${item}`});
      self.largeModal = true;
    },
    viewReportsTableRow(item) {
      let self = this;
      self.editId = item;
      this.$router.push({path: `business-traveller/report/list/${item}`});
    },

    viewResponseTableRow(item) {
      let self = this;
      self.editId = item;
      this.$router.push({path: `business-traveller/response/list/${item}`});
    },

    rowClicked(item, index) {
      this.$router.push({path: `business-traveller/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },
    applyFilter() {
      let self = this;
      let user = "";
      let country = "";
      let state = "";
      let city = "";
      let travellingCountry = "";
      let travellingState = "";
      let travellingCity = "";
      let profileVerified = "";
      let getSubIndustries = [], getIndustries = [];
      if (this.business_traveller.industry != null) {
        this.business_traveller.industry.map(function (value, key) {
          getIndustries[key] = value.value;
        });
      }
      if (this.business_traveller.subIndustry != null) {
        this.business_traveller.subIndustry.map(function (value, key) {
          getSubIndustries[key] = value.value;
        });
      }
      if (this.business_traveller.user != null) {
        user = this.business_traveller.user.value;
      }
      if (this.business_traveller.country != null) {
        country = this.business_traveller.country.value;
      }
      if (this.business_traveller.state != null) {
        state = this.business_traveller.state.value;
      }
      if (this.business_traveller.city != null) {
        city = this.business_traveller.city.value;
      }
      if (this.business_traveller.profileVerified != null) {
        profileVerified = this.business_traveller.profileVerified.value;
      }
      if (this.business_traveller.travellingCountry != null) {
        travellingCountry = this.business_traveller.travellingCountry.value;
      }
      if (this.business_traveller.travellingState != null) {
        travellingState = this.business_traveller.travellingState.value;
      }
      if (this.business_traveller.travellingCity != null) {
        travellingCity = this.business_traveller.travellingCity.value;
      }

      let reqObj = {
        industry: getIndustries,
        subIndustry: getSubIndustries,
        user: user,
        country: country,
        state: state,
        city: city,
        travellingState: travellingState,
        travellingCity: travellingCity,
        travellingCountry: travellingCountry,
        fromDate: self.business_traveller.fromDate,
        toDate: self.business_traveller.toDate,
        profileVerified: profileVerified,
        area: self.business_traveller.area,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },

    resetFilter() {
      this.business_traveller.industry = "";
      this.business_traveller.subIndustry = "";
      this.business_traveller.user = {};
      this.business_traveller.country = {};
      this.business_traveller.state = {};
      this.business_traveller.city = {};
      this.business_traveller.travellingState = {};
      this.business_traveller.travellingCity = {};
      this.business_traveller.travellingCountry = {};
      this.business_traveller.fromDate = {};
      this.business_traveller.toDate = {};
      this.business_traveller.profileVerified = {};
      this.business_traveller.area = {};
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
  },
};
</script>
